import React from "react";
import Image from "next/image";
import {Box, Stack, VStack, useColorModeValue} from "@chakra-ui/react";
import Title from "@/components/title";
import {CERTIFICATES, CERTIFICATE_CONSTANTS} from "./certificate.constants";

const Certificates = () => {
  const borderColor = useColorModeValue("black", "gray.600");
  return (
    <VStack spacing={8}>
      <Title highlight={"Certifications"} title={CERTIFICATE_CONSTANTS.TITLE} />
      <Stack
        borderRadius={"8px"}
        boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
        direction={{base: "column", sm: "row"}}
        mt={6}
        p={4}
        spacing={6}
      >
        {CERTIFICATES.map((certificate) => {
          return (
            <Box
              borderColor={borderColor}
              borderRadius={"8px"}
              borderWidth={"1px"}
              key={certificate}
              p={4}
            >
              <Box
                height={{base: "200px", sm: "150px", md: "200px"}}
                position="relative"
                width={{base: "200px", sm: "150px", md: "200px"}}
              >
                <Image alt="" fill src={certificate} style={{objectFit: "contain"}} />
              </Box>
            </Box>
          );
        })}
      </Stack>
    </VStack>
  );
};

export default Certificates;
