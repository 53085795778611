import React from "react";
import {Divider, Highlight, Text, Stack} from "@chakra-ui/react";

type Props = {
  color?: string;
  highlight?: string;
  isSubTitle?: boolean;
  spacing?: number;
  title: string;
  useColorForHighlight?: boolean;
  withDivider?: boolean;
  withDividerMiddle?: boolean;
};

const Title = (props: Props) => {
  const {
    color,
    highlight,
    isSubTitle = false,
    title,
    useColorForHighlight,
    withDivider,
  } = props;
  return (
    <Stack>
      {withDivider && (
        <Divider
          borderWidth={"2px"}
          borderColor={color || "brand.200"}
          opacity={1}
          width={"50px"}
        />
      )}
      <Text
        color={color}
        fontSize={"36px"}
        fontWeight={300}
        ml={{md: "2rem"}}
        textAlign={"center"}
      >
        <Highlight
          styles={{
            color: useColorForHighlight ? color : "undefined",
            fontWeight: isSubTitle ? 300 : 700,
          }}
          query={highlight ? [highlight] : []}
        >
          {title}
        </Highlight>
      </Text>
    </Stack>
  );
};

export default Title;
